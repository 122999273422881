// REACT AND GATSBY
import React, { useState, useEffect } from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Link from 'reusecore/src/elements/Link';
import Container from 'common/src/components/UI/Container';
// STYLING
import InfoTileWrapper, { Styles } from './infoTile.styles'; // CSS styling
// LOGIC AND HANDLERS
// import { InputHandler } from './handlers'; // Code to handle user I/O

// NPM MODULES
import Dotdotdot from 'react-dotdotdot'
import stripHtml from 'string-strip-html';

// Information Component
const InfoTile = ({
  data,
  tileStyle,            // Converts info tile size on the fly
  onImageClick = null,
  onTitleClick = null,
}) => {
  // Enables/disables showing truncated/html stripped description
  const [fullInfo, updateFullInfo] = useState(false) 
  const [styling, updateStyling] = useState(Styles['small'])
  // Loads styles
  useEffect(() => { updateStyling(Styles[tileStyle]) }, []);
  const { row } = Styles

  // console.log({ tileStyle, styling });
  // http://localhost:8000/users/?userId=9f60361daab54f459eeef1c3987f6c3f
  return (
    <InfoTileWrapper>
      <Container className="userInfo_container">
        <Box bgcolor="gray" {...row}>
          <Box {...styling.podcastImage} >
            <Image
              src={data.image}
              alt="image"
              style={styling.image}
              onClick={async () => {
                if (onImageClick) await onImageClick();
              }}
            />
          </Box>

          {/* TITLE, SUBTITLE, DESCRIPTION */}
          { ( tileStyle !== 'imageOnly' ) ?
          <Box {...styling.podcastInfo} >

            <Box {...styling.title}>
              <Link
                color="secondary"
                onClick={async () => {
                  if (onTitleClick) await onTitleClick();
                }}
              >
                {(fullInfo) ? 
                <h3>{data.title}</h3>:
                <Dotdotdot clamp={styling.title.lines}>
                  <h3>{data.title}</h3>
                </Dotdotdot>}
              </Link>
            </Box>

            {(fullInfo) ? 
            <h4>{data.subtitle}</h4>:
            <Box {...styling.subtitle}>
              <Dotdotdot clamp={styling.subtitle.lines}>
                <h4>{data.subtitle}</h4>
              </Dotdotdot>
            </Box>}

            {(fullInfo) ?
            <>
            <p><div dangerouslySetInnerHTML={{__html: data.description }}/></p>
            <a href="" onClick={ () => updateFullInfo(!fullInfo)}><small>show less</small></a>
            </>:
            <>
            <Box {...styling.description}>
              <Dotdotdot clamp={styling.description.lines}>
                <p>{stripHtml(data.description)}</p>
              </Dotdotdot>
            </Box>
            <a href="" onClick={ () => updateFullInfo(!fullInfo)}><small>show all</small></a>
            </>}

            {/* METADATA */}
            <Box {...row}>
              <Box {...styling.meta1}>
                <small>{data.meta1}</small>
              </Box>
              <Box {...styling.meta2}>
                <small>{data.meta2}</small>
              </Box>
              <Box {...styling.meta3}>
                <small>{data.meta3}</small>
              </Box>
            </Box>
          </Box> : <></>
        }
        </Box>
      </Container>
    </InfoTileWrapper>
  );
};

export default InfoTile;
