import styled from 'styled-components';

export const InfoTileWrapper = styled.section`
  /* padding: 100px 0 0 0;*/
`;
InfoTileWrapper.displayName = 'UserInfoWrapper';

export const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
    bgcolor: 'gray', // Dev/debug
  },

  // Image styling (image only)
  imageOnly: {
    podcastImage: {
      // width: [1 / 2, 1 / 2, 1 / 4, 1 / 4],
      width: 1
    },
    image: {
      width: '100%',
      maxWidth: '250px',
      borderRadius: '10px',
      margin: 'auto',
    }
  },

  // Small sytling (single row)
  small: {
    podcastImage: {
      width: [1 / 5, 1 / 4, 1 / 4, 1 / 4],
    },
    image: {
      width: '100%',
      maxWidth: '180px',
      borderRadius: '10px',
      margin: 'auto',
    },
    podcastInfo: {
      width: [4/5, 3/4, 3/4, 3/4],
      fontSize: ['80%', '100%', '100%', '100%'],
      pl: ['10px', '10px', '30px', '25px', '45px'],
      pr: ['0px', '0px', '30px', '25px', '45px'],
    },
    title: {
      display: 'block',
      lines: 2, // This is technically invalid CSS...but whatever
    },
    subtitle: {
      display: ['none', 'block', 'block', 'block'],
      lines: 2,
    },
    description: {
      display: ['none', 'block', 'block', 'block'],
      lines: 2,
    },
    meta1: {
      width: [1 / 2, 1 / 2, 1 / 3, 1 / 3],
      display: 'block',
    },
    meta2: {
      width: [1 / 2, 1 / 2, 1 / 3, 1 / 3],
      display: 'block',
    },
    meta3: {
      width: 1 / 3,
      display: ['none', 'none', 'block', 'block'],
    },
  },

  // Large styling (hero style)
  large: {
    podcastImage: {
      width: [1, 1 / 4, 1 / 4, 1 / 4],
    },
    image: {
      width: '100%',
      maxWidth: '250px',
      borderRadius: '10px',
      margin: 'auto',
    },
    podcastInfo: {
      width: [1, 3 / 4, 3 / 4, 3 / 4],
      fontSize: ['80%', '100%', '100%', '100%'],
      pl: ['10px', '10px', '30px', '25px', '45px'],
      // pr: ['0px', '0px', '30px', '25px', '45px'],
    },
    title: {
      display: 'block',
      lines: 2, // This is technically invalid CSS...but whatever
    },
    subtitle: {
      display: 'block',
      lines: 2,
    },
    description: {
      display: 'block',
      lines: 2,
    },
    meta1: {
      width: [1 / 2, 1 / 2, 1 / 3, 1 / 3],
      display: 'block',
    },
    meta2: {
      width: [1 / 2, 1 / 2, 1 / 3, 1 / 3],
      display: 'block',
    },
    meta3: {
      width: 1 / 3,
      display: 'block',
    },
  },
};
export default InfoTileWrapper;
